import "./AuthComponent.css";
import logo from "./logo.jpg";
import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

function AuthComponent() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [signInError, setSignInError] = useState(undefined);

  const handleSignIn = (e) => {
    setSignInError(undefined);
    e.preventDefault();
    signInWithEmailAndPassword(getAuth(), email, password)
      .then((userCredential) => {
        // User signed in successfully
        const user = userCredential.user;
        console.log("User signed in:", user);
      })
      .catch((error) => {
        // Handle sign-in errors
        setSignInError(error.code);
        console.error("Sign-in error:", error);
      });
  };

  return (
    <div>
      <div className="background">
        <div className="shape"></div>
        <div className="shape"></div>
      </div>
      <form className="loginForm" onSubmit={handleSignIn}>
        <img className="loginLogo" alt="logotype" src={logo} />
        <label className="loginLabel" htmlFor="username">
          Email
        </label>
        <input
          className="loginInput"
          type="email"
          placeholder="Email"
          value={email}
          id="username"
          onChange={(e) => setEmail(e.target.value)}
        />

        <label className="loginLabel" htmlFor="password">
          Password
        </label>
        <input
          className="loginInput"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          id="password"
        />

        <button className="loginButton" onClick={handleSignIn}>
          Log In
        </button>
        <label className="loginError">{signInError}</label>
      </form>
    </div>
  );
}

export default AuthComponent;
