import "./App.css";
import '@fontsource/poppins';
import React, { useState } from 'react';
import AuthComponent from "./AuthComponent.js";
import LoadingComponent from "./LoadingComponent";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAaNzWdYHnlU2VM1dlV6VRdP2usJTlh1uo",
  authDomain: "genius-app-builder.firebaseapp.com",
  projectId: "genius-app-builder",
  storageBucket: "genius-app-builder.appspot.com",
  messagingSenderId: "242583203658",
  appId: "1:242583203658:web:fb9321ac16efc0f4a4dbb3",
  measurementId: "G-3PYQH5P0DM",
};

// Initialize Firebase
initializeApp(firebaseConfig);

const auth = getAuth()
const LOADING = "loading"

function App() {
  const onSignOutClick = () => {
    signOut(auth)
  };
 
  const [user, setUser] = useState(LOADING);

  onAuthStateChanged(auth, (updatedUser) => {
    if (updatedUser) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      setUser(updatedUser)
      
    } else {
      setUser(undefined)
    }
  });
  let content = <AuthComponent />
  if (user === LOADING) {
    content = <LoadingComponent/>
  }
  else if (user !== undefined) {
    content = <button className="buttonPrimary" onClick={onSignOutClick}>Sign out</button>
  }
  
  return (
    <div className="App">
      <header className="App-header">
      {content}
      </header>
    </div>
  );
}

export default App;
